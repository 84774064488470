var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "div",
          [_c("show-general-errors"), _c("h1", [_vm._v("Component Demo")])],
          1
        ),
        _c("activity-list", {
          attrs: { claimId: "1", tableTitle: "My Activity List Table" },
        }),
        _c("activity-list-embedded", { attrs: { claimId: "1" } }),
        _c(
          "div",
          [
            _c("h2", [_vm._v(" AvoAlert: ")]),
            _c("avo-alert", {
              attrs: { avoAlerts: _vm.avoAlerts },
              on: {
                "update:avoAlerts": function ($event) {
                  _vm.avoAlerts = $event
                },
                "update:avo-alerts": function ($event) {
                  _vm.avoAlerts = $event
                },
              },
            }),
          ],
          1
        ),
        _c("div", [
          _c("h2", [_vm._v("Number formatting")]),
          _vm._v(" formatNumber " + _vm._s(_vm.formatNumber(1234.56789)) + " "),
          _c("br"),
          _vm._v(" formatNumber " + _vm._s(_vm.formatNumber(1234.5)) + " "),
          _c("br"),
          _vm._v(" formatDollars " + _vm._s(_vm.formatDollars(1234.5)) + " "),
          _c("br"),
          _vm._v(
            " formatDollars " + _vm._s(_vm.formatDollars(1234.56789)) + " "
          ),
          _c("br"),
          _vm._v(
            " formatDollars no cents " +
              _vm._s(_vm.formatDollars(1234.5, false)) +
              " "
          ),
          _c("br"),
          _vm._v(
            " formatDollars no cents " +
              _vm._s(_vm.formatDollars(1234.56789, false)) +
              " "
          ),
          _c("br"),
          _vm._v(
            " formatDecimal " + _vm._s(_vm.formatDecimal(1234.56789)) + " "
          ),
          _c("br"),
          _vm._v(" formatDecimal " + _vm._s(_vm.formatDecimal(1234.5)) + " "),
          _c("br"),
        ]),
        _c(
          "div",
          [
            _c("h2", [_vm._v("General Error Handling Demo")]),
            _c("test-errors"),
          ],
          1
        ),
        _c("div", [_c("taxonomy-cSV-upload")], 1),
      ],
      1
    ),
    _c("div", { attrs: { id: "footer" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }