var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c("h5", [_vm._v("Not Found Errors")]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "b-button",
            { staticClass: "m-3", on: { click: _vm.testPatient404 } },
            [_vm._v(" Patient 404 ")]
          ),
          _c(
            "b-button",
            { staticClass: "m-3", on: { click: _vm.testPreauth404 } },
            [_vm._v(" Preauth 404 ")]
          ),
          _c(
            "b-button",
            { staticClass: "m-3", on: { click: _vm.testPractice404 } },
            [_vm._v(" Practice 404 ")]
          ),
        ],
        1
      ),
      _c("h5", [_vm._v("Access Forbidden")]),
      _c("b-button", { staticClass: "m-3", on: { click: _vm.test403 } }, [
        _vm._v(" Raise 403 "),
      ]),
      _c("h5", [_vm._v("Method Not Allowed")]),
      _c("b-button", { staticClass: "m-3", on: { click: _vm.test405 } }, [
        _vm._v(" POST to a GET (405) "),
      ]),
      _c("h5", [_vm._v("General Server Error")]),
      _c("b-button", { staticClass: "m-3", on: { click: _vm.test500 } }, [
        _vm._v(" Raise 500 "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }